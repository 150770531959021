.date-picker-container {
  margin: 5rem 0 2rem 0;
  padding: 0.5rem;
  background: radial-gradient(
    circle,
    rgb(201, 211, 223) 0%,
    rgb(187, 199, 212) 100%
  );
  border-radius: 0.3rem;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.671);
}

.date-picker,
.time-picker {
  margin-top: 0.3rem;
}

.status-container {
  padding-top: 0.3rem;
}

.select-status {
  min-width: 200px;
}

.clean-filter-button {
  background-color: rgb(224, 81, 81);
  border: none;
  color: white;
  border-radius: 0.3rem;
}

.clean-filter-button:hover {
  background-color: rgb(216, 222, 228);
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.671);
}

.button-container {
  margin-top: 0.85rem;
}
