.yellow-color{
    color: rgba(255, 203, 31, 0.979);
}


.error-message{
    font-weight: lighter;
    margin: 0;
    padding: 0.75rem 2rem 0.5rem 1rem;
}

.error-container{
    background-color: rgba(172, 29, 29, 0.979);
    border-radius: 4px;
}
