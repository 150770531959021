.DatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.DatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  color: white;
  border-color: white;
  border-width: 3px;
}

.DatePicker .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.DatePicker.MuiFormControl-root .Mui-focused {
  color: white;
}

.DatePicker.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 2rem;
}

/* @media only screen and (max-width: 700px) {
  .MuiContainer-root.MuiContainer-maxWidthMd.css-xdmu94-MuiContainer-root {
    padding: 0.4rem;
  }
  .MuiGrid-root.css-vwqsel-MuiGrid-root {
    padding: 0.4rem;
  }
  .date-picker {
    padding: 0.4rem;
  }

  .DatePicker {
    width: 75%;
  }
  .MuiFormControl-root.MuiTextField-root.DatePicker.css-132v8a4-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 431px) and (max-width: 700px) {
}
 */
