.message {
  border-radius: 0.3rem;
  background-color: white;
  cursor: pointer;
  margin: 0.5rem 0;
}

.message:hover {
  background-color: rgb(218, 223, 231);
}

.pagination-div {
  display: flex;
  justify-content: center;
}

.ver-mais-btn {
}
