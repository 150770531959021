.container {
  width: 100%;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
}

.detail-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
